import { useEffect, useState } from "react";
import { callAPI } from "../services/config/api-core";

const NewsTicker = () => {
    const [loading, setLoading] = useState(false);
    const [newsTicker, setNewsTicker] = useState([]);

    const myPatientAPI = async () => {
        setLoading(true);
        try {
            const userToken = localStorage.getItem("token");
            const token = userToken && JSON.parse(userToken);

            const header = {
                Authorization: userToken && `Bearer ${token}`,
            };

            const response = await callAPI(
                "/news-ticker",
                true,
                {},
                header,
                false,
                false
            );

            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.news_ticker
            ) {
                setNewsTicker(response.data.data.news_ticker);
            }
        } catch (err) {
            console.error("Error fetching news ticker:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        myPatientAPI();
    }, []);

    return (
        <>

            {!loading ? (
                <div className="marqueeDiv ">

                    <marquee scrollamount={newsTicker?.speed}>
                        <p className="mb-0">
                            <strong>{newsTicker?.heading} </strong>  <i className="fa fa-star text-danger"></i>  {newsTicker?.sub_heading} <i className="fa fa-star text-danger"></i>
                        </p>
                    </marquee>
                </div>
            ) : (
                <></>
            )}

        </>
    );
};

export default NewsTicker;
